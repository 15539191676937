/*
 * @Author: NinJa 625379797@qq.com
 * @Date: 2023-08-30 11:48:08
 * @LastEditors: NinJa 625379797@qq.com
 * @LastEditTime: 2023-08-30 13:06:39
 * @FilePath: /QrCode/src/router/index.js
 * @Description: 
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/CheckList.vue";

Vue.use(VueRouter);

const routes = [
  {
    // 配置默认路由
    path: "/", // 路由地址
    redirect: "/Login", // 重定向
  },
  {
    path: "/CheckList",
    name: "CheckList",
    component: Home,
    meta: { title: "CheckList" },
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("../views/Login"),
    meta: { title: "Login" },
  },
  {
    path: "/404",
    name: "Page404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 };
  // },
});

export default router;
