import { login,getUserInfo } from '@/api/user'
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import { getToken, setToken, removeToken } from '@/utils/auth'
const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo:{}
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER:(state, userInfo)=>{
    state.userInfo = userInfo
  }
}

const actions = {
  login({ commit }, params) {
    return new Promise((resolve, reject) => {
      login(params).then(res => {
        const { data } = res.data
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  setUserInfo({ commit }, data){
    getAction("/api/common/user/current_user").then(res=>{
      commit("SET_USER",res.data)
      data()
    })
  },
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
